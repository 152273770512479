import React, { FC } from "react";

import { UserData } from "../../types/types";
import { PageThankYou } from "../PageThankYou/PageThankYou";

import { FormContent } from "./components/FormContent";
import { Invitation } from "./components/Invitation";

type Props = {
  user: UserData | undefined;
};

const PageForm: FC<Props> = ({ user }) => {
  if (!user) {
    return null;
  }

  return user?.potrebujemOdvoz ? (
    <PageThankYou />
  ) : (
    <>
      <Invitation user={user} />
      <FormContent user={user} />
    </>
  );
};

export { PageForm };
