import React, { FC, useEffect, useState } from "react";
import ReactSelect, { ActionMeta, InputActionMeta } from "react-select";
import { Form, Field, FormikErrors } from "formik";
import cx from "classnames";
import { Link } from "react-router-dom";

import styles from "../../../styles/Form.module.scss";
import { UserAnswers } from "../../../types/types";
import { pronounPeople } from "../../../utils/language";

type SelectOptions = {
  value: number | string;
  label: string;
};

type Props = {
  values: UserAnswers;
  errors: FormikErrors<UserAnswers>;
  handleChange: any;
  isSubmitting: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

const FormForm: FC<Props> = ({
  values,
  errors,
  handleChange,
  isSubmitting,
  setFieldValue,
}) => {
  const peopleOptions: SelectOptions[] = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const bedOptions: SelectOptions[] = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  const semibedOptions: SelectOptions[] = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
  ];

  const roomOptions: SelectOptions[] = [
    { value: "soSZ", label: "Izba so sociálnym zariadením" },
    { value: "bezSZ", label: "Izba bez sociálneho zariadenia" },
  ];

  const [value, setValue] = useState<any>({
    value: values?.kolkoLudiPrivezieme,
    label: values?.kolkoLudiPrivezieme?.toString(),
  });
  const [inputValue, setInputValue] = useState<string>("");

  const [valuePostele, setValuePostele] = useState<any>({
    value: values?.izbaPostele,
    label: values?.izbaPostele?.toString(),
  });
  const [inputValuePostele, setInputValuePostele] = useState<string>("");

  const [valuePristelky, setValuePristelky] = useState<any>({
    value: values?.izbaPristelky,
    label: values?.izbaPristelky?.toString(),
  });
  const [inputValuePristelky, setInputValuePristelky] = useState<string>("");

  const [valueSoc, setValueSoc] = useState<any>({
    value: values?.izbaSocZariadenie,
    label: values?.izbaSocZariadenie?.toString(),
  });
  const [inputValueSoc, setInputValueSoc] = useState<string>("");

  const [commentOpen, setCommentOpen] = useState<boolean>(false);
  const [roomInfoOpen, setRoomInfoOpen] = useState<boolean>(false);

  const handleChangeViemePriviezt = (
    value: any,
    { action }: ActionMeta<any>
  ) => {
    setValue(value);
    setFieldValue("kolkoLudiPrivezieme", value.value);
    setFieldValue("viemePrivieztLudi", "ano");
  };

  const handleInputChangeViemePriviezt = (
    value: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change" || action === "set-value") {
      setInputValue(value);
    }
  };

  const handleChangePostele = (value: any, { action }: ActionMeta<any>) => {
    setValuePostele(value);
    setFieldValue("izbaPostele", value.value);
  };

  const handleInputChangePostele = (
    value: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change" || action === "set-value") {
      setInputValuePostele(value);
    }
  };

  const handleChangePristelky = (value: any, { action }: ActionMeta<any>) => {
    setValuePristelky(value);
    setFieldValue("izbaPristelky", value.value);
  };

  const handleInputChangePristelky = (
    value: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change" || action === "set-value") {
      setInputValuePristelky(value);
    }
  };

  const handleChangeSoc = (value: any, { action }: ActionMeta<any>) => {
    setValueSoc(value);
    setFieldValue("izbaSocZariadenie", value.value);
  };

  const handleInputChangeSoc = (value: string, { action }: InputActionMeta) => {
    if (action === "input-change" || action === "set-value") {
      setInputValueSoc(value);
    }
  };

  useEffect(() => {
    if (
      values.viemePrivieztLudi === "nie" &&
      values?.kolkoLudiPrivezieme !== 0
    ) {
      setFieldValue("kolkoLudiPrivezieme", 0);
      setValue({
        value: 0,
        label: "0",
      });
    }
  }, [values.viemePrivieztLudi]);

  useEffect(() => {
    if (values.potrebujemOdvoz === "ano") {
      setFieldValue("viemePrivieztLudi", undefined);
      setFieldValue("kolkoLudiPrivezieme", 0);
      setValue({
        value: 0,
        label: "0",
      });
    }
  }, [values.potrebujemOdvoz]);

  useEffect(() => {
    if (values.specialneJedlo === "nie") {
      setFieldValue("specialneJedloDruh", "");
    }
  }, [values.specialneJedlo]);

  return (
    <div className={styles.content}>
      <div className={styles.guessForm}>
        <Form>
          {/* KOMENT */}

          <Link onClick={() => setCommentOpen(!commentOpen)} to={"#"}>
            Upraviť počet osôb!
          </Link>

          <div
            className={cx(
              styles.inputContainer,
              !commentOpen ? styles.collapsed : ""
            )}
          >
            <div className={styles.formQuestion}>
              <div style={{ fontWeight: "normal" }}>
                Keď sme trošku mimo, niečo sa zmenilo alebo máš +1, daj nám
                prosím vedieť koľko dospelých a koľko detí dorazí.
              </div>
            </div>
            <Field name="comment" type="text" />
          </div>

          {/* potrebujemOdvoz */}

          <div className={styles.inputContainer}>
            <div className={styles.formQuestion}>
              Budete potrebovať odvoz z Prahy na svatbu?
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.potrebujemOdvoz === "ano"}
                  name="potrebujemOdvoz"
                  onChange={handleChange}
                  type="radio"
                  value="ano"
                />
                Áno. Do Prahy to dáme ľavou zadnou, ale potom potrebujeme
                pomocť.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.potrebujemOdvoz === "nie"}
                  name="potrebujemOdvoz"
                  onChange={handleChange}
                  type="radio"
                  value="nie"
                />
                Nie, vieme sa sami dostať rovno až na farmu. Už sme sa aj kukli
                kade = )
              </label>
            </div>
          </div>

          {/* viemePrivieztLudi */}

          <div
            className={cx(
              styles.inputContainer,
              values.potrebujemOdvoz !== "nie" ? styles.collapsed : ""
            )}
          >
            <div className={styles.formQuestion}>
              V prípade, že pojdete z Prahy vlastným autom, vedeli by ste
              niekoho pribrať? Koľko ľudí?
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.viemePrivieztLudi === "nie"}
                  name="viemePrivieztLudi"
                  onChange={handleChange}
                  type="radio"
                  value="nie"
                />
                Nejdem cez Prahu, resp. sme už plní.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.viemePrivieztLudi === "ano"}
                  name="viemePrivieztLudi"
                  onChange={handleChange}
                  type="radio"
                  value="ano"
                />
                Vieme zobrať
                {/* kolkoLudiPrivezieme */}
                <label>
                  <ReactSelect
                    className={styles.selectPeople}
                    classNamePrefix={"select"}
                    id="kolkoLudiPrivezieme"
                    inputValue={inputValue}
                    isSearchable={false}
                    onChange={handleChangeViemePriviezt}
                    onInputChange={handleInputChangeViemePriviezt}
                    options={peopleOptions}
                    value={value}
                  />
                </label>
                {pronounPeople(values?.kolkoLudiPrivezieme === 1)}
              </label>
            </div>
          </div>

          {/* rezervovatUbytovanie */}

          <div className={styles.inputContainer}>
            <div className={styles.formQuestion}>
              Bohužiaľ ubytovanie priamo na farme Vám vieme len rezervovať,
              zacálovať si ho budete musieť sami = ({" "}
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.rezervovatUbytovanie === "ano"}
                  name="rezervovatUbytovanie"
                  onChange={handleChange}
                  type="radio"
                  value="ano"
                />
                Nevadí, aj tak prídeme. A chceme rezerváciu.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.rezervovatUbytovanie === "nie"}
                  name="rezervovatUbytovanie"
                  onChange={handleChange}
                  type="radio"
                  value="nie"
                />
                Nemáme v pláne prespať. Ešte ten večer sa budeme vracať domov.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.rezervovatUbytovanie === "nie. rusim ucast"}
                  name="rezervovatUbytovanie"
                  onChange={handleChange}
                  type="radio"
                  value="nie. rusim ucast"
                />
                Ejha, toto še mi barz neľubi! Nejdzem na svadbu.
              </label>
            </div>
          </div>

          {/* izba */}

          <div
            className={cx(
              styles.inputContainer,
              values.rezervovatUbytovanie !== "ano" ? styles.collapsed : ""
            )}
          >
            <div className={styles.formQuestion}>
              <span style={{ fontWeight: "normal" }}>
                Na výber je niekoľko variant izieb. Následujúce info není
                povinné, ale uľahčí nám prerozdeľovanie izieb.
                <Link onClick={() => setRoomInfoOpen(!roomInfoOpen)} to={"#"}>
                  &nbsp;Viac&nbsp;info&nbsp;o&nbsp;ubytovaní.
                </Link>
              </span>
              <br />
              <div
                className={cx(
                  styles.infoBlock,
                  !roomInfoOpen ? styles.collapsed : ""
                )}
              >
                Je možné ubytovať sa v izbe bez vlastného socialneho zariadenia{" "}
                <br />
                450 Kč osoba/noc (cca. 19 EUR) <br />
                <br />
                alebo v izbe so socialnym zariadenim <br />
                600 Kč osoba/noc (cca. 25 EUR) <br />
                <br />V oboch prípadoch sú k dispozícii dvoj, troj, štvor i päť
                posteľové izby. Vo vačšine z nich sú k dispozícii aj
                prístelky/palandy resp. rozkladacie gauče.
              </div>
              <br />
              Uvedte prosím koľko postelí{" "}
              <span style={{ fontWeight: "normal" }}>(pre dospelých)</span> a
              koľko prístelok resp. paland{" "}
              <span style={{ fontWeight: "normal" }}>(možno pre deti)</span> by
              ste preferovali na izbe.
            </div>

            <label className={styles.selectWithLabel}>
              <span className={styles.selectLabel}>Postele</span>
              <ReactSelect
                className={styles.selectPeople}
                classNamePrefix={"select"}
                id="izbaPostele"
                inputValue={inputValuePostele}
                isSearchable={false}
                onChange={handleChangePostele}
                onInputChange={handleInputChangePostele}
                options={bedOptions}
                value={valuePostele}
              />
            </label>

            <label className={styles.selectWithLabel}>
              <span className={styles.selectLabel}>Prístelky</span>
              <ReactSelect
                className={styles.selectPeople}
                classNamePrefix={"select"}
                id="izbaPristelky"
                inputValue={inputValuePristelky}
                isSearchable={false}
                onChange={handleChangePristelky}
                onInputChange={handleInputChangePristelky}
                options={semibedOptions}
                value={valuePristelky}
              />
            </label>

            <label className={styles.selectWithLabel}>
              <span className={styles.selectLabel}>Typ izby</span>
              <ReactSelect
                className={styles.selectRoom}
                classNamePrefix={"select"}
                id="izbaSocZariadenie"
                inputValue={inputValueSoc}
                isSearchable={false}
                onChange={handleChangeSoc}
                onInputChange={handleInputChangeSoc}
                options={roomOptions}
                value={valueSoc}
              />
            </label>
          </div>

          {/* specialneJedlo */}

          <div className={styles.inputContainer}>
            <div className={styles.formQuestion}>
              Máte špeciálne preferencie ohľadom jedla?
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.specialneJedlo === "nie"}
                  name="specialneJedlo"
                  onChange={handleChange}
                  type="radio"
                  value="nie"
                />
                Nie. Schálujem všetko, čo predomňa postavíte.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.specialneJedlo === "ano"}
                  name="specialneJedlo"
                  onChange={handleChange}
                  type="radio"
                  value="ano"
                />
                Áno.
              </label>
            </div>
          </div>

          {/* specialneJedloDruh */}

          <div
            className={cx(
              styles.inputContainer,
              values.specialneJedlo !== "ano" ? styles.collapsed : ""
            )}
          >
            <div className={styles.formQuestion}>
              Uveďte prosím Vašetarianstvo alebo iné špeciálne preferencie
            </div>
            <Field name="specialneJedloDruh" type="text" />
          </div>

          {/* odvozNaspat */}

          <div className={styles.inputContainer}>
            <div className={styles.formQuestion}>
              Budete potrebovať odvoz zo svatby naspať do Prahy?
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.odvozNaspat === "nedela"}
                  name="odvozNaspat"
                  onChange={handleChange}
                  type="radio"
                  value="nedela"
                />
                Máme ubytovanie. Potrebujeme odvoz až v nedeľu.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.odvozNaspat === "sobota"}
                  name="odvozNaspat"
                  onChange={handleChange}
                  type="radio"
                  value="sobota"
                />
                Potrebujeme odvoz ešte v deň svadby.
              </label>
            </div>
            <div className={styles.radio}>
              <label>
                <input
                  checked={values.odvozNaspat === "nie"}
                  name="odvozNaspat"
                  onChange={handleChange}
                  type="radio"
                  value="nie"
                />
                Máme svoj odvoz.
              </label>
            </div>
          </div>

          <button disabled={isSubmitting} type="submit">
            Odoslať
          </button>
        </Form>
      </div>
    </div>
  );
};

export { FormForm };
