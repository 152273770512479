import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";

import { UserAnswers, UserData } from "../../../types/types";
import { getGuestDataUpdateApiCall } from "../../api/apiCalls";
import {
  ERROR_PAGE_ROUTE,
  THANK_YOU_PAGE_ROUTE,
} from "../../../constants/routePaths";
import { getRouteTo } from "../../../utils/Utils";

import { FormForm } from "./FormForm";

type Props = {
  user: UserData;
};

const FormContent: FC<Props> = ({ user }) => {
  const navigate = useNavigate();

  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailed, setSendFailed] = useState(false);

  const membersArray = user.members?.split(", ") || [];

  const initialValues: UserAnswers = {
    comment: user?.comment || "",
    potrebujemOdvoz: user?.potrebujemOdvoz || "",
    viemePrivieztLudi: user?.viemePrivieztLudi || "",
    kolkoLudiPrivezieme: user?.kolkoLudiPrivezieme || 0,
    rezervovatUbytovanie: user?.rezervovatUbytovanie || "",
    izbaPostele: user?.izbaPostele || membersArray.length,
    izbaPristelky: user?.izbaPristelky || 0,
    izbaSocZariadenie: user?.izbaSocZariadenie || "",
    odvozNaspat: user?.odvozNaspat || "",
    specialneJedlo: user?.specialneJedlo || "",
    specialneJedloDruh: user?.specialneJedloDruh || "",
  };

  const handleSubmit = (
    values: UserAnswers,
    { setSubmitting }: FormikHelpers<UserAnswers>
  ) => {
    setSubmitting(true);
    getGuestDataUpdateApiCall(
      user.username,
      values,
      setSubmitting,
      setSendSuccess,
      setSendFailed
    );
  };

  useEffect(() => {
    if (sendSuccess) {
      navigate(getRouteTo(THANK_YOU_PAGE_ROUTE));
    }
  }, [sendSuccess]);

  useEffect(() => {
    if (sendFailed) {
      navigate(getRouteTo(ERROR_PAGE_ROUTE));
    }
  }, [sendFailed]);

  return (
    <Formik<UserAnswers> initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <FormForm
          errors={errors}
          handleChange={handleChange}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          values={values}
        />
      )}
    </Formik>
  );
};

export { FormContent };
