import React, { FC, ReactElement } from "react";

import styles from "../../../styles/Info.module.scss";

type Props = {
  question: string;
  questionAnswer: string;
  questionLink?: ReactElement;
};

const InformationItem: FC<Props> = ({
  question,
  questionAnswer,
  questionLink,
}) => (
  <div className={styles.informationBox}>
    <div className={styles.question}>{question}</div>
    <div className={styles.answer}>
      {questionAnswer} {questionLink && questionLink}
    </div>
  </div>
);

export { InformationItem };
