import React, { FC } from "react";

import styles from "../../../styles/Page.module.scss";
import { UserData } from "../../../types/types";
import { Header } from "../../Header/Header";
import { PageLogout } from "../../PageLogout/PageLogout";

type Props = {
  noNavigation?: boolean;
  children: React.ReactNode;
  user?: UserData | undefined;
};

const PageWrapper: FC<Props> = ({ noNavigation, children, user }) => {
  if (user && Object.keys(user).length === 0) {
    return <PageLogout />;
  }

  return (
    <div className={styles.pageWrapper}>
      {!noNavigation && <Header />}
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  );
};

export { PageWrapper };
