import React, { FC } from "react";
import { Outlet } from "react-router-dom";

import {
  FORM_PAGE_ROUTE,
  INFO_PAGE_ROUTE,
  INTRO_PAGE_ROUTE,
} from "../../../constants/routePaths";

import { NavigationItem } from "./NavigationIItem";

type Props = {
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navigation: FC<Props> = ({ setMenuOpen }) => (
  <>
    <nav>
      <ul>
        <NavigationItem
          label={"Úvod"}
          route={INTRO_PAGE_ROUTE}
          setMenuOpen={setMenuOpen && setMenuOpen}
        />
        <NavigationItem
          label={"Informácie pre Vás"}
          route={INFO_PAGE_ROUTE}
          setMenuOpen={setMenuOpen && setMenuOpen}
        />
        <NavigationItem
          label={"Potvrdenie účasti"}
          route={FORM_PAGE_ROUTE}
          setMenuOpen={setMenuOpen && setMenuOpen}
        />
      </ul>
    </nav>

    <Outlet />
  </>
);

export { Navigation };
