import React, { FC } from "react";
import { Link } from "react-router-dom";

import { INTRO_PAGE_ROUTE, LOGIN_PAGE_ROUTE } from "../../constants/routePaths";
import { UserData } from "../../types/types";
import { getRouteTo } from "../../utils/Utils";

type Props = {
  user: UserData;
};

const PageError: FC<Props> = ({ user }) => (
  <div style={{ padding: "0 20px" }}>
    <br />
    <h1>Ouuuups</h1>
    <div>Pardon, niečo sa pokazilo na našej strane. Skúsime to znova?</div>
    <br />
    {user?.username ? (
      <Link to={getRouteTo(INTRO_PAGE_ROUTE)}>Prejsť na úvod</Link>
    ) : (
      <Link to={getRouteTo(LOGIN_PAGE_ROUTE)}>Znova sa prihlásiť</Link>
    )}
  </div>
);

export { PageError };
