import React from "react";

import styles from "./styles/App.module.scss";
import { PageRoutes } from "./components/Pages/PageRoutes";

function App() {
  return (
    <div className={styles.App}>
      <PageRoutes />
    </div>
  );
}

export default App;
