import React, { FC, useState } from "react";
import cx from "classnames";

import styles from "../../styles/Header.module.scss";

import { Logo } from "./components/Logo";
import { NavigationMobile } from "./components/NavigationMobile";
import { NavigationDesktop } from "./components/NavigationDesktop";

type Props = {};

const Header: FC<Props> = () => {
  const [shrinkedClass, setShrinkedClass] = useState<string>("");

  window.onscroll = () => {
    if (document.documentElement.scrollTop === 0) {
      setShrinkedClass("");
    }

    if (document.documentElement.scrollTop > 0) {
      setShrinkedClass(styles.shrinked);
    }
  };

  return (
    <div className={cx(styles.header, shrinkedClass)}>
      <Logo />
      <NavigationDesktop />
      <NavigationMobile />
    </div>
  );
};

export { Header };
