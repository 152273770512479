import React, { FC } from "react";
import { Link } from "react-router-dom";

import { getRouteTo } from "../../../utils/Utils";

type Props = {
  label: string;
  route: string;
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavigationItem: FC<Props> = ({ route, label, setMenuOpen }) => {
  const handleClick = () => {
    setMenuOpen && setMenuOpen(false);
  };

  return (
    <li>
      <Link onClick={handleClick} to={getRouteTo(route)}>
        {label}
      </Link>
    </li>
  );
};

export { NavigationItem };
