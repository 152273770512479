import { UserData } from "../../types/types";
import { PageLogout } from "../PageLogout/PageLogout";
import { PageForm } from "../PageForm/PageForm";
import { PageInformations } from "../PageInformations/PageInformations";
import { PageIntro } from "../PageIntro/PageIntro";
import { PageLogin } from "../PageLogin/PageLogin";
import { PageError } from "../PageError/PageError";
import { PageThankYou } from "../PageThankYou/PageThankYou";

import { PageWrapper } from "./PageWrapper/PageWrapper";

const getLoginPage = (
  user: UserData,
  setUser: React.Dispatch<React.SetStateAction<UserData>>
) => (
  <PageWrapper noNavigation>
    <PageLogin setUser={setUser} user={user} />
  </PageWrapper>
);

const getIntroPage = (user: UserData) => (
  <PageWrapper user={user}>
    <PageIntro user={user} />
  </PageWrapper>
);

const getInformationsPage = (user: UserData) => (
  <PageWrapper user={user}>
    <PageInformations />
  </PageWrapper>
);

const getFormPage = (user: UserData) => (
  <PageWrapper user={user}>
    <PageForm user={user} />
  </PageWrapper>
);

const getThankYouPage = (user: UserData) => (
  <PageWrapper user={user}>
    <PageThankYou />
  </PageWrapper>
);

const getLogoutPage = () => (
  <PageWrapper noNavigation>
    <PageLogout />
  </PageWrapper>
);

const getErrorPage = (user: UserData) => (
  <PageWrapper noNavigation>
    <PageError user={user} />
  </PageWrapper>
);

export {
  getIntroPage,
  getInformationsPage,
  getFormPage,
  getLoginPage,
  getLogoutPage,
  getThankYouPage,
  getErrorPage,
};
