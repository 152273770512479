import React, { FC } from "react";
import { Link } from "react-router-dom";

import styles from "../../../styles/Header.module.scss";
import { INTRO_PAGE_ROUTE } from "../../../constants/routePaths";
import { getRouteTo } from "../../../utils/Utils";

type Props = {};

const Logo: FC<Props> = () => (
  <div className={styles.logo}>
    <Link to={getRouteTo(INTRO_PAGE_ROUTE)}>MIRKA & JOZEF</Link>
  </div>
);

export { Logo };
