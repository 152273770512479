import React, { FC, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  ERROR_PAGE_ROUTE,
  FORM_PAGE_ROUTE,
  INFO_PAGE_ROUTE,
  INTRO_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  THANK_YOU_PAGE_ROUTE,
} from "../../constants/routePaths";
import { UserData } from "../../types/types";
import ScrollToTop from "../common/ScrollToTop";

import {
  getLogoutPage,
  getFormPage,
  getInformationsPage,
  getIntroPage,
  getLoginPage,
  getThankYouPage,
  getErrorPage,
} from ".";

const PageRoutes: FC = () => {
  const [user, setUser] = useState<UserData>({} as UserData);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          element={getLoginPage(user, setUser)}
          index
          path={LOGIN_PAGE_ROUTE}
        />
        <Route element={getIntroPage(user)} path={INTRO_PAGE_ROUTE} />
        <Route element={getInformationsPage(user)} path={INFO_PAGE_ROUTE} />
        <Route element={getFormPage(user)} path={FORM_PAGE_ROUTE} />
        <Route element={getThankYouPage(user)} path={THANK_YOU_PAGE_ROUTE} />
        <Route element={getLogoutPage()} path={LOGOUT_PAGE_ROUTE} />
        <Route element={getErrorPage(user)} path={ERROR_PAGE_ROUTE} />
        {/* <Route
          element={<Navigate to={getRouteTo(INTRO_PAGE_ROUTE)} />}
          path={ALL_OTHERS_PAGE_ROUTE}
        /> */}
      </Routes>
    </BrowserRouter>
  );
};

export { PageRoutes };
