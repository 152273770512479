export const pronounLet = (isSingle: boolean) => (isSingle ? "Daj" : "Dajte");
export const pronounYou = (isSingle: boolean) => (isSingle ? "Ťa" : "Vás");
export const pronounYour = (isSingle: boolean) => (isSingle ? "Tvoje" : "Vaše");
export const pronounYours = (isSingle: boolean) =>
  isSingle ? "Tvojej" : "Vašej";
export const pronounPeople = (isSingle: boolean) =>
  isSingle ? "človeka" : "ľudí";
export const pronounletsmall = (isSingle: boolean) =>
  isSingle ? "daj" : "dajte";
export const pronounYouWere = (isSingle: boolean) =>
  isSingle ? "si bol" : "ste boli";
export const pronounConfirm = (isSingle: boolean) =>
  isSingle ? "Potvrď" : "Potvrďte";
