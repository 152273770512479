import React, { FC } from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

import { FORM_PAGE_ROUTE, INFO_PAGE_ROUTE } from "../../constants/routePaths";
import styles from "../../styles/Intro.module.scss";
import { UserData } from "../../types/types";
import {
  pronounConfirm,
  pronounYou,
  pronounYouWere,
} from "../../utils/language";
import { getRouteTo } from "../../utils/Utils";

type Props = {
  user: UserData;
};

const PageIntro: FC<Props> = ({ user }) => {
  const { members } = { ...user };

  const familyMemebers = members?.split(", ") || [];

  const isSingle = familyMemebers?.length === 1;

  return (
    <div>
      <Parallax
        bgClassName={styles.logoBackground}
        bgImage={"./maj.jpg"}
        strength={0}
      >
        <div className={styles.logoBackgroundWrap}>
          <div className={styles.logo}>
            MIRKA <span className={styles.and}>&</span> JOZEF
          </div>
        </div>
      </Parallax>

      <div className={styles.textBlock}>
        Hupsnem do toho a chceme aby {pronounYouWere(isSingle)} pri tom...
        <h1>Pozývame {pronounYou(isSingle)} na našu svatbu!</h1>
        Uskutoční sa
        <h1>6. mája 2023</h1>
        na malebnej farme v Herouticiach
        <div className={styles.buttonWrapper}>
          <Link className={styles.buttonis} to={getRouteTo(INFO_PAGE_ROUTE)}>
            Viac info pre Vás...
          </Link>
        </div>
      </div>
      <Parallax
        bgClassName={styles.farmaBackground}
        bgImage={"./hostina.jpg"}
        strength={500}
      >
        <div style={{ height: 400 }}></div>
      </Parallax>
      <div className={styles.textBlock}>
        <h1>{pronounConfirm(isSingle)} účasť</h1>
        Tešíme sa na stretnutie a poprosíme {pronounYou(isSingle)} o potvrdenie
        účasti a pár dopľňujúcich informácií...
        <div className={styles.buttonWrapper}>
          <Link className={styles.buttonis} to={getRouteTo(FORM_PAGE_ROUTE)}>
            Potvrdiť účasť
          </Link>
        </div>
      </div>
    </div>
  );
};

export { PageIntro };
