import React, { FC, useState } from "react";
import cx from "classnames";

import styles from "../../../styles/Header.module.scss";

import { Navigation } from "./Navigation";

const NavigationMobile: FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div className={styles.mobileNav}>
      <div onClick={() => setMenuOpen(!menuOpen)}>
        <img alt="Menu" src="./menu_icon.png" style={{ width: "20px" }} />
      </div>

      <div
        className={cx(
          styles.mobileMenuWrapper,
          menuOpen ? styles.open : styles.close
        )}
      >
        <div
          className={styles.mobileMenuOuter}
          onClick={() => setMenuOpen(false)}
        />

        <div className={styles.mobileMenu}>
          <Navigation setMenuOpen={setMenuOpen} />
        </div>
      </div>
    </div>
  );
};

export { NavigationMobile };
