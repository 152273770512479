import axios from "axios";

import { API_URL } from "../../config";
import { UserAnswers, UserData } from "../../types/types";

export const getAllGuestsApiCall = async (
  setData: React.Dispatch<React.SetStateAction<UserData[]>>
) =>
  axios
    .get(`${API_URL}/api/get`)
    .then((data: any) => {
      setData(data.data);
    })
    .catch((error: any) => {});

export const getGuestDataApiCall = (
  username: string,
  setData: React.Dispatch<React.SetStateAction<UserData | undefined>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  axios
    .get(`${API_URL}/api/getFrom/${username}`)
    .then((data: any) => {
      setData(data.data[0]);
    })
    .catch((error: any) => {
      setError(error);
    })
    .finally(() => setIsLoading(false));
};
export const getGuestDataUpdateApiCall = (
  username: string,
  answers: UserAnswers,
  setSubmitting: (isSubmitting: boolean) => void,
  setSendSuccess: (isSent: boolean) => void,
  setSendFailed: (isError: boolean) => void
) => {
  setSubmitting(true);

  axios
    .post(`${API_URL}/api/answers/${username}`, answers)
    .then((data: any) => {
      setSendSuccess(true);
    })
    .catch((error: any) => {
      setSendFailed(true);
    })
    .finally(() => setSubmitting(false));
};
