import React, { FC } from "react";
import { Parallax } from "react-parallax";

import styles from "../../../styles/Form.module.scss";
import { UserData } from "../../../types/types";
import { pronounLet, pronounYour, pronounYours } from "../../../utils/language";

type Props = {
  user: UserData;
};

const Invitation: FC<Props> = ({ user }) => {
  const { members } = { ...user };

  const familyMemebers = members?.split(", ") || [];

  const isSingle = familyMemebers?.length === 1;

  const numberOfPersons = isSingle
    ? "príde 1 osoba"
    : familyMemebers?.length > 4
    ? `príde ${familyMemebers?.length} osôb`
    : `prídu ${familyMemebers?.length} osoby`;

  return (
    <div className={styles.invitation}>
      <Parallax
        bgClassName={styles.introBackground}
        bgImage={"./dotaznik.jpg"}
        strength={500}
      >
        <div className={styles.introBackgroundWrap}>
          <div className={styles.paralexContent}>
            <h1>{pronounLet(isSingle)} nám vedieť...</h1>
            <div>
              Odpovedzte nám prosím na pár otázok. Jednak nám tým potvrdíte
              účasť a zároveň nám {pronounYour(isSingle)} odpovede uľahčia
              organizáciu veselky.
              <br />
              <br />
            </div>
          </div>
        </div>
      </Parallax>

      <div className={styles.content}>
        <h3>
          Očakávame, že z {pronounYours(isSingle)} strany {numberOfPersons}:
        </h3>
      </div>
      <h2>{members}</h2>
    </div>
  );
};

export { Invitation };
