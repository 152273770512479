import React, { FC } from "react";

import styles from "../../../styles/Header.module.scss";

import { Navigation } from "./Navigation";

const NavigationDesktop: FC = () => (
  <div className={styles.desktopNav}>
    <Navigation />
  </div>
);

export { NavigationDesktop };
