import React, { FC } from "react";

const PageThankYou: FC = () => (
  <div style={{ padding: "0 20px" }}>
    <h1>Ďakujeme</h1>
    <div>Vaše odpovede sú zaznamenané.</div>
    <div>Ak nastala zmena, ozvite sa prosím priamo Mirke alebo Jožkovi.</div>
  </div>
);

export { PageThankYou };
