import React, { FC, useEffect, useState } from "react";
import { Navigate } from "react-router";

import styles from "../../styles/Form.module.scss";
import { UserData } from "../../types/types";
import { INTRO_PAGE_ROUTE } from "../../constants/routePaths";
import { FieldTextInput } from "../common/FieldTextInput";
import { getGuestDataApiCall } from "../api/apiCalls";
import { errorMessages } from "../../constants/errors";

type Props = {
  user: UserData;
  setUser: React.Dispatch<React.SetStateAction<UserData>>;
};

const PageLogin: FC<Props> = ({ user, setUser }) => {
  const [inputName, setInputName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      if (user && user?.username === inputName) {
        setIsSubmitted(true);

        return;
      }

      // setErrors([{ name: "username", message: errorMessages.uname }]);
    }
  }, [user]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    let { username } = document.forms[0];

    setInputName(username.value);

    getGuestDataApiCall(
      username.value,
      setUser as React.Dispatch<React.SetStateAction<UserData | undefined>>,
      setIsLoading,
      setFetchError
    );
  };

  const getButtonTitle = (): string =>
    isLoading ? "Overujem..." : "Prihlásiť";

  const renderLoginForm = (error?: Error[]) => (
    <form onSubmit={handleSubmit}>
      <h3>
        Prihlás sa s údajmi, <br /> ktoré ti poslal Jožko alebo Mirka
      </h3>
      <FieldTextInput
        errors={error}
        id={"username"}
        label={"Meno"}
        type={"text"}
      />
      {/* <FieldTextInput
            errors={errors}
            id={"password"}
            label={"Heslo"}
            type={"password"}
          /> */}
      <div className={styles.buttonContainer}>
        <input
          title={getButtonTitle()}
          type="submit"
          value={getButtonTitle()}
        />
      </div>
    </form>
  );

  return (
    <div className={styles.loginForm}>
      {isSubmitted ? (
        <Navigate replace={true} to={INTRO_PAGE_ROUTE} />
      ) : fetchError ? (
        renderLoginForm([{ name: "username", message: errorMessages.uname }])
      ) : (
        renderLoginForm()
      )}
    </div>
  );
};

export { PageLogin };
