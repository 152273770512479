import React, { FC } from "react";

import styles from "../../styles/Form.module.scss";
import { Error } from "../../types/types";

type Props = {
  errors?: Error[];
  id: string;
  label: string;
  type: string;
};

const FieldTextInput: FC<Props> = ({
  id,
  label,
  errors: errorMessages,
  type,
}) => {
  const errorMessage = errorMessages?.find((message) => message.name === id);

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={id}>{label}</label>
      <input id={id} name={id} type={type} />
      {errorMessage && (
        <div className={styles.error}>{errorMessage.message}</div>
      )}
    </div>
  );
};

export { FieldTextInput };
