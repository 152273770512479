import React, { FC } from "react";
import { Link } from "react-router-dom";

import { LOGIN_PAGE_ROUTE } from "../../constants/routePaths";
import { getRouteTo } from "../../utils/Utils";

type Props = {};

const PageLogout: FC<Props> = () => (
  <div style={{ padding: "0 20px" }}>
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>Fuuu-ha!</h1>
    <div>
      Bol si ohlásený. Pravdepodobne si refreshol stránku, alebo spravil nejakú
      podobnú kravinu. Takže to nerob!
    </div>
    <br />
    <Link to={getRouteTo(LOGIN_PAGE_ROUTE)}>Znova sa prihlásiť</Link>
  </div>
);

export { PageLogout };
