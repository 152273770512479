import React, { FC } from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";

import styles from "../../styles/Info.module.scss";

import { InformationItem } from "./components/InformationItem";

type Props = {};

const PageInformations: FC<Props> = () => (
  <>
    <Parallax
      bgClassName={styles.introBackground}
      bgImage={"./farma_hp.jpg"}
      strength={500}
    >
      <div className={styles.introBackgroundWrap}>
        <div className={styles.paralexContent}>
          <h1>Užitočné informácie</h1>
          <div className={styles.subtitle}>
            Všetko, čo potrebujete vedieť...
          </div>
        </div>
      </div>
    </Parallax>
    <div className={styles.content}>
      <InformationItem
        question="Kedy bude svadba?"
        questionAnswer="Svadba sa koná 6. mája 2023. Obrad bude o 14:30."
      />
      <InformationItem
        question="Kde to bude?"
        questionAnswer="Obrad aj samotná hostina sa odohraje na Farme Heroutice. Je to asi 40 min. autom z Prahy, kúsok od Benešova."
      />
      <InformationItem
        question="Farma?"
        questionAnswer="Áno. Je to veľký koňský ranč s 'jizdírnou', obrovskym pozemkom, rybníkom a majiteľom z Prešova = )"
      />
    </div>

    <Parallax
      bgClassName={styles.farmaBackground}
      bgImage={"./ubytovani.jpg"}
      strength={500}
    >
      <div className={styles.farmaBackgroundWrap}>
        <div className={styles.paralexContent}>
          <h2>Farma Heroutice</h2>
          <Link target={"_blank"} to={"https://farmaheroutice.cz/"}>
            Viac info
          </Link>
        </div>
      </div>
    </Parallax>
    <div className={styles.content}>
      <InformationItem
        question="Ako sa dostanem na miesto svadby?"
        questionAnswer="Ak sa viete dopraviť na miesto svadby vlastným autom, nižšie je mapa. 
        V opačnom prípade nám dajte vedieť pri potvrdení účasti v dotazníku a my Vám zabezpečíme dopravu z Prahy až na miesto."
      />
      <InformationItem
        question="Bude zaistené ubytovanie?"
        questionAnswer="Samozrejme. Farma je fakt veľká a priamo na mieste vedia ubytovať až 120 ľudí. 
        Takže izby Vám zarezervujeme, bohužiaľ ale nie je v naších možnostiach zaplatiť všetkým hosťom izbu."
      />
      <InformationItem
        question="Tak ako je to s izbami?"
        questionAnswer="Na výber sú izby bez socialneho zariadenia (vlastná kúpelňa) za 450 Kč za osobu/noc (cca. 19 EUR) alebo izby so socialnym zariadenim za 600 Kč za noc (cca. 25 EUR).
        V oboch prípadoch sú k dispozícii dvoj, troj, štvor i päť posteľové izby. Vo vačšine z nich sú k dispozícii aj prístelky/palandy resp. rozkladacie gauče."
      />
      <InformationItem
        question="Môžem so sebou doviezť psa?"
        questionAnswer="Áno. S domácimi miláčikmi na farme určite nebude problém."
      />
      <InformationItem
        question="Máte doporučený dress code?"
        questionAnswer="Nie. Žiadny karneval sa nekoná, ale v saku to chlapom viac sluší."
      />
      <InformationItem
        question="Máte zoznam svadobných darov?"
        questionAnswer="Nemáme. Je to určite na Vás, ale sme toho názoru, že je pre všetkých jednoduchšie nevláčiť veľa vecí."
      />
      <InformationItem
        question="Ako je to s jedlom?"
        questionAnswer="Hostina bude formou servírovaneho 'obeda', neskôr otvorený raut a večerný grill až do neskorých hodín."
      />
      <InformationItem
        question="Bude na hostine vegetariánske/veganske jedlo?"
        questionAnswer="Dajte nám prosím v dotazníku vedieť ak preferujete vegetariánske/veganske jedlo, resp. potrebujete špeciálny jedálniček."
      />
      <InformationItem
        question="A čo after-party?"
        questionAnswer="Schválne sme vybrali termín, keď je na Slovensku aj v Čechách v pondelok voľno. 
        Majiteľ nás uistil, že ak budeme chcieť, môžme ostať až do pondelka. V prípade záujmu, to dohodneme na mieste."
      />
    </div>
    <Parallax
      bgClassName={styles.mapaBackground}
      bgImage={"./heroutice.png"}
      strength={500}
    >
      <div className={styles.mapaBackgroundWrap}>
        <div className={styles.paralexContent}>
          <h2>Farma Heroutice</h2>
          <Link
            target={"_blank"}
            to={
              "https://www.google.cz/maps/place/Farma+Heroutice/@49.7677249,14.4214071,11.25z/data=!4m6!3m5!1s0x470b78872a3dc8ed:0xa280a5912b589dbd!8m2!3d49.7692396!4d14.5186495!16s%2Fg%2F11b6dq47k_?hl=en-GB"
            }
          >
            Pozriet na mape
          </Link>
        </div>
      </div>
    </Parallax>
  </>
);

export { PageInformations };
